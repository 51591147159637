<template>
  <div>
  </div>
</template>

<script>
export default {
  name :'ztk-taobao-auth-callback',
  async created() {
    let success = false;
    try {
      await this.$http.doApi('fw-taobao-access-saveAuth', {}, this.$route.query);
      success = true;
    } catch(e) {
      console.log(e);
    }

    if (success) {
      await this.$alert('授权成功');
    } else {
      await  this.$alert('授权失败');
    }
      window.close();
  }
}
</script>

<style>

</style>